import Headroom from "headroom.js";
import Fuse from "fuse.js";
import Glide from "@glidejs/glide";
import * as _ from "lodash";
import stickybits from "stickybits";
import { load } from "recaptcha-v3";
import moment from 'moment';
import LazyLoad from "vanilla-lazyload";

(function() {
    window.smartTec = {};
    var _BACKEND_URL = "/api/";
    var BACKEND_URL = "https://smarttec-dev.web2.standpunkt-hosting.de/api/";
    var TOPICS = "topics";
    var CONTACT = "contact";

    // Getting test data for search
    function Get(url) {
        var Httpreq = new XMLHttpRequest();
        Httpreq.open("GET", url, false);

        Httpreq.send(null);
        return Httpreq.responseText;
    }

    // var topicsList = JSON.parse(Get(BACKEND_URL + TOPICS));

    window.smartTec.header = function() {
        return {
            searchInput: "",
            searchResults: [],
            isSearchBarshown: false,
            areSearchResultsShown: false,
            selected: null,
            search() {
                let options = {
                    shouldSort: true,
                    includeScore: true,
                    threshold: 0.5,
                    location: 0,
                    distance: 100,
                    minMatchCharLength: 1,
                    keys: ["title", "text"],
                };
                let fuse = new Fuse(topicsList, options);
                this.searchResults = fuse.search(this.searchInput);
            },
            searchHover() {
                this.isSearchBarshown = true;
                setTimeout(() => {
                    this.$refs.searchField.focus();
                });
            },
            cutString(str) {
                if (str.length > 100) {
                    return str.substring(0, 100) + "...";
                } else {
                    return str;
                }
            },
        };
    };
    window.smartTec.headerMobile = function() {
        return {
            searchInput: "",
            searchResults: [],
            isSearchBarshown: false,
            areSearchResultsShown: false,
            accordionIndex: null,
            search() {
                let options = {
                    shouldSort: true,
                    includeScore: true,
                    threshold: 0.5,
                    location: 0,
                    distance: 100,
                    minMatchCharLength: 1,
                    keys: ["title", "text"],
                };
                let fuse = new Fuse(topicsList, options);
                this.searchResults = fuse.search(this.searchInput);
            },
            searchHover() {
                this.isSearchBarshown = true;
                setTimeout(() => {
                    this.$refs.searchField.focus();
                });
            },
            cutString(str) {
                if (str.length > 100) {
                    return str.substring(0, 100) + "...";
                } else {
                    return str;
                }
            },
        };
    };

    window.smartTec.sponsorsSlider = function() {
        return {
            data: ["bla", "bla", "bla", "hh"],
            sliderInit() {
                new Glide(this.$refs.sponsorsSlider, {
                    type: "carousel",
                    autoplay: 3000,
                    startAt: 0,
                    perView: 3,
                    gap: 30,
                    breakpoints: {
                        800: {
                            perView: 2,
                        },
                        480: {
                            perView: 1,
                        },
                    },
                }).mount();
            },
        };
    };
    window.smartTec.teamSlider = function() {
        return {
            sliderInit() {
                new Glide(this.$refs.teamSlider, {
                    type: "carousel",
                    startAt: 0,
                    perView: 3,
                    breakpoints: {
                        1300: {
                            perView: 2,
                        },
                        480: {
                            perView: 1,
                        },
                    },
                }).mount();
            },
        };
    };
    window.smartTec.heroSlider = function() {
        return {
            sliderInit() {
                new Glide(this.$refs.heroSlider, {
                    type: "carousel",
                    startAt: 0,
                    perView: 1,
                    autoplay: 5000,
                    gap: 0,
                }).mount();
            },
        };
    };
    window.smartTec.bannerSlider = function() {
        return {
            imgArray: ["hero-start", "hero-start", "hero-test", "hero-start"],
            sliderInit() {
                new Glide(this.$refs.bannerSlider, {
                    type: "carousel",
                    startAt: 0,
                    perView: 1,
                    autoplay: 3000,
                    gap: 0,
                }).mount();
            },
            getImgClass(imgClass) {
                return imgClass;
            },
        };
    };

    window.smartTec.magazine = function() {
        return {
            articles: topicsList,
            activeBtn: "all",
            highlights: [],
            filterOptions: [],
            sortOptions: ["Datum", "Titel", "Kategorie"],
            dateOptions: ["letzte 2 Wochen", "dieser Monat", "dieses Jahr"],
            categoriesList: topicsList,
            categoryNames: [],
            initFilterOptions() {
                topicsList.forEach((topic) => {
                    topic.tags.forEach((element) => {
                        this.categoryNames.push(element);
                    });
                });
                this.categoryNames = _.uniq(this.categoryNames);

                this.filterOptions = _.concat(this.dateOptions, this.categoryNames);

                this.sortBy('Datum');
            },
            timeStamptoDate(timeStamp) {
                var date = new Date(timeStamp * 1000);
                var day = date.getDate();
                var month = "0" + date.getMonth();
                var year = "0" + date.getFullYear();

                var formattedTime =
                    day + "." + month.substr(-2) + "." + year.substr(-4);
                return formattedTime;
            },
            getDateObject(timeStamp) {
                return new Date(timeStamp * 1000);
            },
            isHighlight(index) {
                if (index == 0 || index % 4 === 0) {
                    return true;
                } else {
                    return false;
                }
            },
            isEvenHighlight(index) {
                var highlights = [];
                for (var i = 0; i < topicsList.length; i++) {
                    if (i == 0 || i % 4 === 0) {
                        highlights.push(i);
                    }
                }
                const indexInHighlights = _.findIndex(highlights, (item) => {
                    return item == index;
                });
                if (indexInHighlights % 2 == 0) {
                    return true;
                } else {
                    return false;
                }
            },
            showFilter() {
                this.activeBtn = "filter";
            },
            getTags(article) {
                var tags = [];
                _.forEach(article.tags, (tag, index) => {
                    tags.push(tag);
                });

                return tags;
            },
            filterBy(option) {
                // category filter
                if (_.includes(this.categoryNames, option)) {
                    this.articles = _.filter(topicsList, (topic) => {
                        return _.some(topic.tags, (tag) => {
                            return tag == option;
                        });
                    });
                } else if (option == "dieses Jahr") {
                    const now = moment();
                    this.articles = topicsList.filter((topic) => {
                        const topicMoment = moment(topic.created_at);
                        return topicMoment.isSame(now, 'y');
                    })
                } else if (option == "dieser Monat") {
                    const now = moment();
                    this.articles = topicsList.filter((topic) => {
                        const topicMoment = moment(topic.created_at);
                        return topicMoment.isSame(now, 'month');
                    })
                } else if (option == "letzte 2 Wochen") {
                    const twoWeeksAgo = moment().subtract(2, 'w');
                    this.articles = topicsList.filter((topic) => {
                        const topicMoment = moment(topic.created_at);
                        return topicMoment.isAfter(twoWeeksAgo);
                    })
                }
                this.closeDropdown();
            },
            closeDropdown() {
                this.activeBtn = "";
            },
            showAll() {
                this.activeBtn = "all";
                this.articles = topicsList;
            },
            showSortDropdown() {
                this.activeBtn = "sort";
            },
            sortBy(option) {
                if (option == "Datum") {
                    this.articles = _.reverse(_.sortBy(this.articles, ["created_at"]));
                } else if (option == "Title") {
                    this.articles = _.sortBy(this.articles, ["title"]);
                } else if (option == "Kategorie") {
                    this.articles = _.sortBy(this.articles, (topic) => {
                        return topic.tags;
                    });
                }
                this.closeDropdown();
            },
            textTrim(str) {
                if (str.length > 300) {
                    return str.substring(0, 450) + "...";
                } else {
                    return str;
                }
            },
        };
    };
    window.smartTec.contact = function() {
        return {
            tab: "contact",
            redirectUrl: "",
            form: {
                firstname: "",
                lastname: "",
                email: "",
                phone: "",
                message: "",
                object: "",
                agb: "",
                title: "",
                interests: [],
                recaptchaToken: "",
            },
            formErrors: false,
            emailInvalid: false,
            sendingStatus: "",
            sendingText: "",
            siteKey: "6LfKQ_YUAAAAAPEy5kzYnBzwWkKk-q3WLHdlKBLe",
            sendForm(e) {
                if (
                    this.form.firstname &&
                    this.form.lastname &&
                    this.form.title &&
                    this.form.interests &&
                    this.form.agb &&
                    this.form.object &&
                    this.form.phone
                ) {
                    const expression = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    const validEmail = expression.test(
                        String(this.form.email).toLowerCase()
                    );
                    //Ignore valid email without removing code
                    if (validEmail || true) {
                        this.emailInvalid = false;
                        var formSendRequest = new XMLHttpRequest();
                        let url = BACKEND_URL + CONTACT;
                        formSendRequest.open("POST", url, true);
                        formSendRequest.send(JSON.stringify(this.form));
                        var instance = this;
                        formSendRequest.onreadystatechange = function(aEvt) {
                            if (
                                formSendRequest.readyState === XMLHttpRequest.DONE &&
                                formSendRequest.status === 200
                            ) {
                                instance.success();
                            } else {
                                instance.handleErrors(formSendRequest.status);
                            }
                        };
                    } else {
                        this.emailInvalid = true;
                        e.preventDefault();
                    }
                } else {
                    this.formErrors = true;
                    e.preventDefault();
                }
            },
            success() {
                window.location.href = this.redirectUrl;

                // this.sendingStatus = "success";
                // this.sendingText = "Ihre Anfrage wurde erfolgreich gesendet!";
            },
            handleErrors(status) {
                if (status === 500) {
                    this.sendingText =
                        "Ups! Fehler auf dem Server, versuchen Sie später nochmal";
                } else {
                    this.sendingText = "Ups! Fehler aufgetreten, versuchen Sie nochmal";
                }
                this.sendingStatus = "error";
            },
            onRecaptchaLoadCallback() {
                load(this.siteKey).then((recaptcha) => {
                    recaptcha.execute("contactForm").then((token) => {
                        this.form.recaptchaToken = token;
                    });
                });
            },
        };
    };

    var lazyLoad = new LazyLoad();
    window.lazy = lazyLoad;
    // Headroom
    // grab an element
    var elem = document.querySelector("header");
    stickybits("header");
    var logo = document.querySelector(".logo-hero");
    // construct an instance of Headroom, passing the element
    var headroom = new Headroom(elem, {
        offset: 205,
        tolerance: 5,
        classes: {
            initial: "animated",
            pinned: "slideDown",
            unpinned: "slideUp",
        },
    }); // initialise
    headroom.init();

    var logoroom = new Headroom(logo, {
        offset: 205,
        tolerance: 5,
        classes: {
            initial: "animated",
            pinned: "slideDown",
            unpinned: "slideUp",
        },
    }); // initialise
    logoroom.init();
})();